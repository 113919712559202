import React from "react";
import classNames from "classnames";
import { Link } from "../link";

const Cta = ({ cta, className, target = "_self", onClick }) => {
  return (
    cta.value &&
    cta.content?.ctaLink?.value &&
    cta.content.ctaLink.value !== "#" && (
      <div className="row">
        <div className="col-md-12 text-center">
          <Link
            onClick={onClick}
            to={cta.content.ctaLink.value}
            target={target}
            title={cta.value}
            className={classNames(
              "cta-link",
              className,
              cta.content.colorebottone?.value
                ? `btn-color-${cta.content.colorebottone?.value}`
                : ""
            )}
          >
            {cta.value}
          </Link>
        </div>
      </div>
    )
  );
};

export default Cta;
